import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, Input, Modal } from 'reactstrap'
import LocationTableContainerForTemplate from 'components/Common/LocationTableContainerForTemplate'
import MapTemplateBox from './MapTemplateBox'
import Success from 'pages/NotificationModal/Success'



const TemplateMaps = (props) => {

    const [loading, setLoading] = useState(false)
    const [data, setData]  = useState([])
    const api_url = process.env.REACT_APP_API_URL
    const [templateName, setTemplateName] = useState(props.templateName)
    const [selectedLoc, setSelectedLoc] = useState([])
    const [showMapConfirmation, setShowMapConfirmation] = useState(false)
    const [showMapSuccess, setShowMapSuccess] = useState(false)

    const handleCheck = (row) => {
        const m_loc_id = row.original.m_loc_id;

        if (selectedLoc?.includes(m_loc_id)) {
            const newSelectedLocs = selectedLoc.filter((item, index) => item !== m_loc_id)
            setSelectedLoc(newSelectedLocs)
        } else {

           setSelectedLoc(...selectedLoc, m_loc_id)
        }
        
    }

    const isIncluded = (m_loc_id) => {
        return selectedLoc.includes(m_loc_id)
    }

    const getColumns = () => {
        return useMemo(
            () => [
                {
                    accessor:"m_loc_id",
                    Header: "",
                    Cell: ({ row }) => (
                  
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                        disabled={row.original.templateName?.length>0 || row.original.templateName == templateName}
                        // checked={row.original.checked}
                        onChange={() => {handleCheck(row)}}
                      />
                    )
                    
                },
                {
                    accessor: "tLocationName",
                    Header: "Location Name",
                },
                {
                    accessor: "templateName",
                    Header: 'Template Name'
                }
                
                
            ],
            []
        )
    }

    useEffect(() => {
        debugger
        setSelectedLoc([])
        setTemplateName(props.templateName)
        fetchData()
        
        
    }, [])

    const columns = getColumns();

    const fetchData = async () => {
        debugger;
        
        setLoading(true)
        try {
            const token = localStorage.getItem("idToken")
            const headers = { Authorization: `Bearer ${token}` }

            var url = api_url

            url = url + "email/masterlocations"

            const response = await fetch(url, { headers })

            if (!response.ok) {
                if ([401, 403].includes(response.status)) {
                    logout()
                }
                throw new Error("Request failed")
            }

            const jsonData = await response.json()
            setData(jsonData)
            setLoading(false)
        } catch (error) {
            console.error("Error:" + error)
            setLoading(false)
            console.error(error)
        }
    }

    const mapToTemplate = (locs) => {
        setShowMapConfirmation(true)
        setSelectedLoc(locs)
    }

    const togMapConfirm = () => {
        setShowMapConfirmation(!showMapConfirmation)
    }

    const executeMap = async () => {

        setLoading(true)
        try {
            const token = localStorage.getItem("idToken")
            const headers = { Authorization: `Bearer ${token}` }

            const reqObj = {
                m_loc_ids: selectedLoc
            }


            const config = {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(reqObj),
                
            }

            var url = api_url

            url = url + "email/masterlocations/template/"+templateName+"/map"


            const response = await fetch(url, config)

            if (!response.ok) {
                if ([401, 403].includes(response.status)) {
                    logout()
                }
                throw new Error("Request failed")
            }

            const jsonData = await response.json()
            setLoading(false)
            setShowMapSuccess(true)
            setSelectedLoc([])
            fetchData()
        } catch (error) {
            console.error("Error:" + error)
            setLoading(false)
            console.error(error)
        }




       
    }

    const togMapSuccess = () => {
        setShowMapConfirmation(false)
        setShowMapSuccess(!showMapSuccess)
        props.refresh()
    }



    return (
        <>
        <Modal
                isOpen={showMapConfirmation}
                toggle={() => {
                    togMapConfirm()
                }}
                centered
                size="md"
            >
                <MapTemplateBox setShowPublishConfirm={setShowMapConfirmation} loading={loading} executeMap={executeMap} setLoading={setLoading} template={data} />
            </Modal>

            <Modal
                isOpen={showMapSuccess}
                toggle={() => {
                    togMapSuccess()
                }}
                centered
                size="md"
            >
                <Success message="Successfully mapped locations to the template" close={togMapSuccess} />
            </Modal>
        <Card className="border mt-3 mb-3" outline color="primary">
            <CardBody><LocationTableContainerForTemplate
                columns={columns}
                data={data}
                isGlobalFilter={true}
                isAddOptions={true}
                customPageSize={50}
                //handleAddMapping={handleAddMapping}
                //editRow={editRow}
                //editingRow={editingRow}
                mapDisabled={false}
                loading={loading}
                refreshTable={fetchData}
                className="custom-header-css"
                mapToTemplate={mapToTemplate}
                currentTemplate={templateName}
            /></CardBody>
        </Card>
        </>

    )
}

export default TemplateMaps