import React from "react"

import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import ResetPassword from "pages/Auth/ResetPassword"
import OrderDashboard3 from "pages/OrderDashboard3/OrderDashboard"
import Login3 from "pages/AuthenticationInner/Login3"
import LocationManagement from "pages/Location-Mngt"
import AcceptAppointment from "pages/AcceptAppointment"
import AddLocationMapping from "pages/AddLocationMapping"
import Notification from "pages/Notification"
import UserAdmin from "pages/UserManagement"
import CreateUser from "pages/UserManagement/CreateUser"
import ReqScheduler from "pages/ReqScheduler"
import LocationConfiguration from "pages/LocationConfiguration"
import OpenDockLocationConfig from "pages/LocationConfiguration/OpenDockLocationConfig"
import TenantCustomisation from "pages/TenantCustomisation"
import AuthCode from "pages/Auth/AuthCode"
import CognitoIdp from "pages/Cognito-IDP"
import AuthorizationConfig from "pages/Cognito-IDP/AuthorizationConfig"
import AuthGrantFlow from "pages/Cognito-IDP/AuthGrantFlow"
import ClientCredGrantFlow from "pages/Cognito-IDP/ClientCredGrantFlow"
import SAMLEditForm from "pages/Cognito-IDP/SAMLEditForm"
import EmailTemplate from "pages/EmailTemplate"
import EmailAddressConfig from "pages/EmailAddressConfig"
import NewLocation from "pages/NewLocation"
import EmailLocationMapping from "pages/EmailLocationMapping"
import ApptReqEmailTemplate from "pages/ApptReqEmailTemplate"
import CreateTemplate from "pages/ApptReqEmailTemplate/CreateTempalte"
import TemplateDetails from "pages/ApptReqEmailTemplate/TemplateDetails"
import TemplateData from "pages/ApptReqEmailTemplate/TemplateData"
import CloneTemplate from "pages/ApptReqEmailTemplate/CloneTemplate"

const authProtectedRoutes = [
  { path: "/dashboard", component: <OrderDashboard3 /> },
  { path: "/location-management", component: <LocationManagement /> },
  { path: "/stop-accept-appointment", component: <AcceptAppointment /> },
  { path: "/add-location-mapping", component: <AddLocationMapping /> },
  { path: "/user-admin", component: <UserAdmin /> },
  { path: "/notification", component: <Notification /> },
  { path: "/create-user", component: <CreateUser /> },
  {
    path: "/req-scheduler",
    component: <ReqScheduler />,
  },
  {
    path: "/loc-config-open-dock",
    component: <LocationConfiguration />,
  },
  {
    path: "/loc-config-open-dock/:locationId/:locationName",
    component: <OpenDockLocationConfig />,
  },
  {
    path: '/tenant-customisation',
    component: <TenantCustomisation />
  },
  {
    path: '/auth-config',
    component: <AuthorizationConfig />
    // component: <CognitoIdp />
  },

  {
    path: '/auth-config/code',
    component: <AuthGrantFlow />
  },
  {
    path: '/auth-config/new',
    component: <CognitoIdp />
  },
  {
    path: '/auth-config/clientcred',
    component: <ClientCredGrantFlow />
  },
  {
    path:'/auth-config-saml/new',
    component: <SAMLEditForm />

  },
  {
    path:'/email-template/:locationId/:locationName',
    component: <EmailTemplate />
  }, 
  {
    path: '/email-address-config/:locationId/:locationName',
    component: <EmailAddressConfig />
  }, 
  {
    path: '/new-location/:locationId/:locationName',
    component: <EmailLocationMapping />
  },
  {
    path: '/new-location',
    component: <NewLocation />
  },
  {
    path: '/email-template',
    component: <ApptReqEmailTemplate />
  },
  {
    path: '/create-email-template',
    component: <CreateTemplate />
  },
  {
    path: '/email-template/view/:name',
    component: <TemplateData />
  },
  {
    path: '/email-template/clone/:name',
    component: <CloneTemplate />
  }



 
]

const publicRoutes = [
  { path: "/", component: <Login3 /> },
  { path: "/resetpassword", component: <ResetPassword /> },
  { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  {
    path: "/auth", component:<AuthCode />

  }
]

export { authProtectedRoutes, publicRoutes }
