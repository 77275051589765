import React, { useEffect, useState } from 'react'
import { Button, Col, Label, Modal, Popover, PopoverBody, PopoverHeader, Row, Table } from 'reactstrap'
import VariableForm from './VariableForm'
import { Tbody, Thead } from 'react-super-responsive-table'

const VarData = (props) => {

    const [vars, setVars] = useState(props.vars || [])
    const [showAddForm, setShowAddForm] = useState(false)
    const [editingVar, setEditingVar] = useState(null)
    const [editingVarIndex, setEditingVarIndex] = useState(null)
    const [showPopover, setShowPopover] = useState(Array(10).fill(false));

    const addVar = () => {
        setEditingVar(null)
        setEditingVarIndex(null)
        setShowAddForm(true)
    }

    const togAddForm = () => {
        setShowAddForm(!showAddForm)
    }

    useEffect(() => {
        debugger
        console.log(vars)
        setEditingVar(editingVar)
    }, [editingVar])

    const addNewVar = (val) => {
        if (editingVar) {
            var variables = [...vars]
            variables[editingVarIndex] = val
            setVars(variables)
            props.setVars(variables)

        } else {
            if (vars) {
                var variables = [...vars]
                variables.push(val)
                setVars(variables)
                props.setVars(variables)
            } else {
                var variables = []
                variables.push(val)
                setVars(variables)
                props.setVars(variables)
            }
        }

        setEditingVar(null)
        setEditingVarIndex(null)
        togAddForm()

    }

    const editVar = (val, index) => {
        setEditingVar(val)
        setEditingVarIndex(index)
        setShowAddForm(true)
    }

    const removeVar = (index) => {
        var vars = vars?.filter((item, idx) => idx !== index)
        setVars(vars)
        props.setVars(vars)
    }

    const handlePopOverToggle = (index) => {
        const newShowPopover = [...showPopover];
        newShowPopover[index] = !newShowPopover[index];
        setShowPopover(newShowPopover);
    };




    return (
        <>
            <Modal isOpen={showAddForm}
                toggle={togAddForm}
            >
                <VariableForm closeModal={togAddForm} onSave={addNewVar} varData={editingVar} vars={vars} isNew={props.isNew} isEdit={props.isEdit} />
            </Modal>
            <Row>
                <Label
                    htmlFor="desc"
                    className="col-sm-2 col-form-label"
                >
                    Variables
                </Label>
                <Col md={10}>
                    {(!vars || vars.length == 0) && !props.isEdit && <div className='text-muted'>{"No variables added."}</div>}
                    {vars?.length > 0 &&
                        <Table>
                            <Thead>
                                <tr>
                                    <th style={{ border: 'none' }}>
                                        Name
                                    </th>
                                    <th style={{ border: 'none' }}>
                                        Type
                                    </th>
                                    <th style={{ border: 'none' }}>
                                        Value
                                    </th>
                                    <th style={{ border: 'none' }}>
                                        Notes
                                    </th>

                                    {props.isEdit && <th style={{ border: 'none' }}>
                                        Action
                                    </th>}
                                </tr>
                            </Thead>
                            <Tbody>
                                {vars.map((val, index) => (
                                    <tr key={index}>

                                        <td style={{ border: 'none' }}>
                                            {val.name}
                                        </td>
                                        <td style={{ border: 'none' }}>
                                            {val.type}
                                        </td>
                                        <td style={{ border: 'none' }}>
                                            {val.value}
                                        </td>
                                        <td style={{ border: 'none' }}>
                                            {val.notes && val.notes.length > 0 && <i className='mdi mdi-information-variant text-primary ' style={{ fontSize: 'large' }}
                                                id={val.name}
                                                onClick={() => {
                                                    handlePopOverToggle(index);
                                                }} />}

                                            {val.notes && val.notes.length > 0 && <Popover
                                                placement="top"
                                                isOpen={showPopover[index]}
                                                target={val.name}
                                                toggle={() => {
                                                    handlePopOverToggle(index);
                                                }}
                                            >
                                                <PopoverHeader>Notes</PopoverHeader>
                                                <PopoverBody>
                                                    {val.notes}
                                                </PopoverBody>
                                            </Popover>}
                                        </td>

                                        {props.isEdit && <td style={{ border: 'none' }}>
                                            <i className="mdi mdi-pencil font-size-16 text-success me-1 pointer" onClick={() => editVar(val, index)} />{" "}
                                            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" onClick={() => removeVar(index)} />

                                        </td>}
                                    </tr>
                                ))}

                            </Tbody>
                        </Table>}

                    {props.isEdit && <Button type="button" className="btn, btn-sm" color="primary" onClick={addVar}>Add</Button>}

                </Col>
            </Row>
        </>
    )
}

export default VarData